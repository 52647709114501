<template>
  <div>
    <b-card title="Filter">
      <b-row>
        <b-col md="3" class="mb-md-0 mb-2">
          <label>ID</label>
          <b-form-input
            id="cpfInput"
            v-model="cpfKey"
            placeholder="ID（CPF/CNPJ）"
          />
        </b-col>
        <b-col md="3" class="mb-md-0 mb-2">
          <label>Name</label>
          <b-form-input
            id="nameInput"
            v-model="nameKey"
            placeholder="USER NAME"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3" class="mb-md-0 mb-2">
          <label>CPF Verity</label>
          <v-select
            v-model="cpfFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="modeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>
        <b-col md="3" class="mb-md-0 mb-2">
          <label>IDWALL Verity</label>
          <v-select
            v-model="idwallFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="modeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>

        <b-col md="5" class="mb-md-0 mb-2">
          <b-button class="mt-2 mr-3" variant="primary" @click="search">
            Search
          </b-button>
          <b-button class="mt-2 mr-3" variant="outline-primary" @click="reset">
            Reset
          </b-button>
          <!-- <b-button class="mt-2" variant="outline-primary" @click="download">
            Download
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="verifyCPF"
            >
              <feather-icon
                icon="UserCheckIcon"
                class="mr-50"
              />
              <span class="align-middle">CPF Verify</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="queryIdwallBasicInfo"
            >
              <feather-icon icon="UserCheckIcon" class="mr-50" />
              <span class="align-middle">Idwall Basic</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="queryIdwallFullInfo"
            >
              <feather-icon icon="UserCheckIcon" class="mr-50" />
              <span class="align-middle">Idwall Full</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="records"
        primary-key="id_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(choose)="data">
          <b-form-checkbox v-model="data.item.is_check" />
        </template>
        <template #cell(id_no)="data">
          <b-link
            target="_blank"
            :to="{
              name: 'id-detail',
              query: { id_no: data.item.id_no, id_type: data.item.id_type },
            }"
            class="font-weight-bold d-block"
          >
            {{ data.item.id_no }}
          </b-link>
        </template>

        <template #cell(cpf_verify)="data">
          {{ formatVerity(data.item.cpf_verify) }}
        </template>

        <template #cell(idwall_verify)="data">
          {{ formatIdwallVerity(data.item.idwall_verify) }}
        </template>

        <template #cell(aliveness)="data">
          {{ formatVerity(data.item.aliveness) }}
        </template>

        <template #cell(match_verify)="data">
          {{ formatVerity(data.item.match_verify) }}
        </template>

        <template #cell(pep_verify)="data">
          {{ formatVerifyPEP(data.item.pep_verify) }}
        </template>

        <template #cell(sanction_verify)="data">
          {{ formatVerifySanction(data.item.sanction_verify) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="UserXIcon"
              size="16"
              class="cursor-pointer mx-1"
              @click="addblack(data.item)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <Aml-Add-Black-Sidebar
        :is-aml-add-black-sidebar-active.sync="isAmlAddBlackSidebarActive"
        :data="blackList"
      />
    </b-card>
    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BFormInput,
  BButton,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BPagination,
  BOverlay,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import amlIdListJs from './amlIdList'
import AmlAddBlackSidebar from './AmlAddBlackSidebar.vue'

const {
  fetchAmlIdList, downloadAmlIdList, verifyCPFId, verifyIdwallInfo,
} = amlIdListJs()

export default {
  components: {
    BFormInput,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    vSelect,
    BPagination,
    BOverlay,
    BLink,
    AmlAddBlackSidebar,
  },
  data() {
    return {
      loading: false,
      cpfKey: '',
      nameKey: '',
      searchRequest: {},
      records: [],
      veritys: [],
      blackList: [],
      verityList: [],
      idwallInfoList: [],
      isIdVeritySidebarActive: false,
      isAmlAddBlackSidebarActive: false,
      isIdwallVerifySidebarActive: false,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      total: 0,
      tableFields: [
        { key: 'choose', label: 'choose' },
        { key: 'name', label: 'Name' },
        { key: 'id_no', label: 'ID NO.' },
        { key: 'id_type', label: 'ID Type' },
        { key: 'cpf_verify', label: 'CPF Verity' },
        { key: 'idwall_verify', label: 'idwall Verity' },
        { key: 'aliveness', label: 'Aliveness' },
        { key: 'match_verify', label: 'Match CPF&Name' },
        { key: 'pep_verify', label: 'IS PEP' },
        { key: 'sanction_verify', label: 'IS Sanction' },
        'actions',
      ],
      idwallFilter: '',
      cpfFilter: '',
      modeOptions: [
        { label: 'ALL', value: '' },
        { label: 'YES', value: 1 },
        { label: 'NO', value: 0 },
      ],
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  methods: {
    formatVerity(value) {
      if (value === 1) {
        return 'YES'
      }
      if (value === 2) {
        return 'NO'
      }
      return 'Unknown'
    },
    formatIdwallVerity(value) {
      if (value === 1) {
        return 'Basic'
      }
      if (value === 2) {
        return 'Full'
      }
      if (value === 3) {
        return 'Basic/Full'
      }
      return 'UnVerified'
    },
    formatVerifyPEP(value) {
      if (value === 1) {
        return 'NO'
      }
      if (value === 2) {
        return 'YES'
      }
      return 'Unknown'
    },
    formatVerifySanction(value) {
      if (value === 1) {
        return 'NO'
      }
      if (value > 1) {
        return 'YES'
      }
      return 'Unknown'
    },
    isVerify(value) {
      switch (value) {
        case '':
          return null
        case '1':
          return true
        case '0':
          return false
        default:
          return null
      }
    },
    getUserName() {
      const userString = localStorage.getItem('userData')
      if (userString !== null && userString !== undefined) {
        const user = JSON.parse(userString)
        return user.realName
      }
      return ''
    },
    getUserId() {
      const userString = localStorage.getItem('userData')
      if (userString !== null && userString !== undefined) {
        const user = JSON.parse(userString)
        return user.uid
      }
      return ''
    },
    reset() {
      this.idwallFilter = ''
      this.cpfFilter = ''
      this.nameKey = ''
      this.cpfKey = ''
      this.searchRequest.page_num = 1
    },
    addblack(item) {
      if (item) {
        this.isAmlAddBlackSidebarActive = true
      }
      this.blackList = []
      const userName = this.getUserName()
      const obj = {}
      obj.id_no = item.id_no
      obj.name = item.user_name
      obj.id_type = item.id_type
      obj.user_name = userName
      obj.email = item.email
      obj.mobile = item.mobile
      this.blackList.push(obj)
      if (!this.isAmlAddBlackSidebarActive) {
        this.showAlert('warning', 'Please Choose To Join The Blacklist')
      }
    },
    verifyCPF() {
      this.loading = true
      this.veritys = []
      this.verityList = []
      const userName = this.getUserName()
      this.records.forEach(item => {
        if (item.is_check) {
          if (!this.isIdVeritySidebarActive) {
            this.isIdVeritySidebarActive = true
          }
          const cpfRequest = {}
          cpfRequest.id_no = item.id_no
          cpfRequest.name = item.name
          cpfRequest.id_type = item.id_type
          cpfRequest.user_name = userName
          this.verityList.push(cpfRequest)
        }
      })
      if (this.verityList.length <= 0) {
        this.showAlert('warning', 'Please Select Verification Record')
        this.loading = false
        return
      }
      verifyCPFId(
        this.verityList,
        () => {
          this.search(this.searchRequest)
        },
        fail => {
          this.showAlert('warning', `Fetch Verify with ${fail}`)
          this.loading = false
        },
      )
    },
    queryIdwallInfo(reqUrl) {
      let params = null
      this.loading = true
      this.records.forEach(item => {
        if (item.is_check === true) {
          params = {
            id_no: item.id_no,
            id_type: item.id_type,
          }
          this.idwallInfoList.push(params)
        }
      })
      if (!this.idwallInfoList) {
        this.showAlert('warning', 'Please Select Verificatioin Record')
        this.loading = false
        return
      }
      const reqParam = {}
      reqParam.idwallInfoList = this.idwallInfoList
      reqParam.url = reqUrl
      verifyIdwallInfo(
        reqParam,
        () => {
          this.search(this.searchRequest)
        },
        fail => {
          this.showAlert('warning', `Get Idwall Info with ${fail}`)
          this.loading = false
        },
      )
    },
    queryIdwallBasicInfo() {
      this.queryIdwallInfo('/risk/idwall/basic/query')
    },
    queryIdwallFullInfo() {
      this.queryIdwallInfo('/risk/idwall/full/query')
    },
    download() {
      this.searchRequst()
      this.searchRequest.uid = this.getUserId()
      downloadAmlIdList(
        this.searchRequest,
        () => {
          this.showAlert('success', 'Download ID Info', 'Please go to the download page')
        },
        fail => {
          this.showAlert('warning', `Fetch download with ${fail}`)
        },
      )
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    search(params) {
      this.searchRequest = params
      this.searchRequst()
      fetchAmlIdList(
        this.searchRequest,
        records => {
          this.records = records.list
          this.total = records.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.total - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.total
          this.dataMeta.of = this.total
          this.$bvToast.toast('Success fetching data', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
          this.loading = false
        },
        fail => {
          this.showAlert('warning', `Fetch records with ${fail}`)
          this.loading = false
        },
      )
    },
    searchRequst() {
      this.searchRequest.is_idwall = this.isVerify(this.idwallFilter)
      this.searchRequest.is_cpf = this.isVerify(this.cpfFilter)
      this.searchRequest.name = this.nameKey
      this.searchRequest.id_no = this.cpfKey
      this.searchRequest.user_name = this.getUserName()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
