import store from '@/store'
import amlIdStoreModule from '@/pagsmile/amlStoreModule'

export default function amlIdListJs() {
  const AML_STORE_MODULE_NAME = 'aml'

  // Register module
  if (!store.hasModule(AML_STORE_MODULE_NAME)) store.registerModule(AML_STORE_MODULE_NAME, amlIdStoreModule)

  const fetchAmlIdList = (params, success, fail) => {
    store
      .dispatch('aml/fetchAmlIdList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const downloadAmlIdList = (params, success, fail) => {
    store
      .dispatch('aml/downloadAmlIdList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const verifyCPFId = (params, success, fail) => {
    store
      .dispatch('aml/verifyCPFId', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const verifyIdwallInfo = (params, success, fail) => {
    store
      .dispatch('aml/verifyIdwallInfo', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
  }
  const saveIdAml = (params, success, fail) => {
    store
      .dispatch('aml/saveIdAml', params)
      .then(response => {
        const { code, msg } = response.data

        if (code === '10000') {
          success(msg)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const fetchAmlBlackList = (params, success, fail) => {
    store
      .dispatch('aml/fetchAmlBlackList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const downloadAmlBlackList = (params, success, fail) => {
    store
      .dispatch('aml/downloadAmlBlackList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const deleteIdAml = (params, success, fail) => {
    store
      .dispatch('aml/deleteIdAml', params)
      .then(response => {
        const { code, msg } = response.data

        if (code === '10000') {
          success(msg)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const fetchIdInfo = (params, success, fail) => {
    store
      .dispatch('aml/fetchIdInfo', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const confirmSanction = (params, success, fail) => {
    store
      .dispatch('aml/confirmSanction', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const fetchOrderIdInfoList = (params, success, fail) => {
    store
      .dispatch('aml/fetchOrderIdInfoList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const fetchOrderIdList = (params, success, fail) => {
    store
      .dispatch('aml/fetchOrderIdList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const fetchDownloadList = (params, success, fail) => {
    store
      .dispatch('aml/fetchAmlDownloadList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error catch') })
  }
  return {
    verifyCPFId,
    verifyIdwallInfo,
    downloadAmlIdList,
    fetchAmlIdList,
    saveIdAml,
    deleteIdAml,
    downloadAmlBlackList,
    fetchAmlBlackList,
    fetchIdInfo,
    confirmSanction,
    fetchOrderIdInfoList,
    fetchOrderIdList,
    fetchDownloadList,
  }
}
