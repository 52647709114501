<template>
  <b-sidebar
    id="aml-add-black-sidebar"
    :visible="isAmlAddBlackSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-aml-add-black-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Add Black
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card title="Id Info">
        <b-row v-for="item in blackList" :key="item.id_no">
          <b-col md="12">
            <dl class="row">
              <dt class="col-sm-3">
                ID NO.:
              </dt>
              <dd class="col-sm-9">
                {{ item.id_no }}({{ item.id_type }})
              </dd>
            </dl>
          </b-col>
        </b-row>
      </b-card>
      <validation-observer ref="simpleRules">
        <b-form class="p-2">
          <validation-provider
            #default="{ errors }"
            name="Reason"
            rules="required"
          >
            <b-form-group label="Reason" label-for="reason">
              <b-form-input
                id="reason"
                v-model="blackInfo.reason"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Remark" label-for="remark">
            <b-form-textarea
              id="remark"
              v-model="blackInfo.remark"
              rows="3"
              no-resize
            />
          </b-form-group>
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import amlIdListJs from './amlIdList'

const {
  saveIdAml,
} = amlIdListJs()

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCard,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAmlAddBlackSidebarActive',
    event: 'update:is-aml-add-black-sidebar-active',
  },
  props: {
    isAmlAddBlackSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      blackInfo: {
        reason: '',
        remark: '',
      },
      blackList: [],
      required,
      amls: [],
    }
  },
  watch: {
    isAmlAddBlackSidebarActive(val) {
      if (!val) {
        return
      }
      this.blackList = this.data
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.updateItem()
            }
          })
        }
      })
    },
    updateItem() {
      this.amls = []
      this.blackList.forEach(item => {
        const obj = {}
        obj.id_no = item.id_no
        obj.user_name = item.name
        obj.id_type = item.id_type
        obj.email = item.email
        obj.mobile = item.mobile
        obj.update_user = item.user_name
        obj.reason = this.blackInfo.reason
        obj.remark = this.blackInfo.remark
        this.amls.push(obj)
      })
      saveIdAml(
        this.amls,
        () => {
          this.showAlert('success', 'Add Blacklist Success', 'Save Ok')
          this.$emit('update:is-aml-add-black-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', `Add Blacklist Error ${fail}`)
        },
      )
    },
    initValues() {
      this.blackList = {
        reason: '',
        remark: '',
      }
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#aml-add-black-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  .text-center-align {
    text-align: center;
  }
}
</style>
